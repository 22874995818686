<template>
  <main-card class="dns-host-add">
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <order-config
        v-else-if="config"
        :configurator="config"
        :period="0"
        class="dns-host-add__config"
        @init="onConfigInit"
        @ready="onConfigReady"
        @notready="onConfigNotReady"
      />
    </transition>
    <template #footer>
      <transition name="fade" mode="out-in">
        <div v-if="configIsInit" class="dns-host-add__summary">
          <div class="dns-host-add__summary-title big-title">
            {{ $t('summary') + ':' }}
          </div>
          <div class="dns-host-add__summary-price medium-text">
            {{ $n(price, 'currency') }}
          </div>
        </div>
      </transition>
    </template>
    <template #footerEnd>
      <transition name="fade" mode="out-in">
        <div v-if="configIsInit" class="dns-host-add__btns">
          <base-button
            :disabaled="!configIsValid || !func || (isSending && !skipBasket)"
            :loading="isSending && skipBasket"
            class="dns-host-add__btn"
            @click="sendToPay()"
          >
            {{ price ? $t('order') : $t('activate') }}
            <!--            @click="send(true)"-->
          </base-button>
          <base-button
            :disabaled="!configIsValid || !func || (isSending && skipBasket)"
            :loading="isSending && !skipBasket"
            theme="outlined"
            color="default"
            class="dns-host-add__btn"
            @click="sendToBasket()"
          >
            <!--            @click="send(false)"-->
            {{ $t('basket') }}
          </base-button>
        </div>
      </transition>
    </template>
  </main-card>
</template>

<script>
import OrderConfig from '@/components/Configurator/OrderConfig.vue';
import { parseCost } from '@/utils/parsers/costParser';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import { BillMgrAddon } from '@/models/BillMgr/BillMgrAddon';
import { debounce } from 'lodash';
import qs from 'qs';
import wizardPay from '@/mixins/billmgr/wizardPay';
import showErrorModal from '@/mixins/showErrorModal';
import handleRedirect from '@/mixins/billing/handleRedirect';

import updateBalance from '@/mixins/billing/updateBalance';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import OrderSummary from '@/components/Configurator/OrderSummary';
export default {
  name: 'DnsHostAdd',
  components: { OrderConfig },
  mixins: [billMgrToolActions, wizardPay, showErrorModal, handleRedirect, updateBalance],
  data() {
    return {
      moduleMain: 'moduleDomains.moduleDomainsDnsHost',
      configIsInit: false,
      configData: null,
      configIsValid: false,
      order: '',
      cost: 0,
      formData: null,
      config: null,
      period: 12,
      pricelist: null,
      isCalculating: false,
      isSendingToBasket: false,
      isSendingToPay: false,
      startFunc: 'payment.add',
      startParams: {},
      configuratorClass: PaymentMethodConfigurator,
      isLoading: false,
      isSending: false,
      skipBasket: false,
      id: '431',
      func: '',
    };
  },
  computed: {
    list() {
      return this.$store.state.moduleDomains.moduleDnshostOrder.list;
    },
    moduleMainState() {
      return this.getModuleState(this.moduleMain);
    },
    moduleMainPath() {
      return this.moduleMain.replaceAll('.', '/');
    },
    configurator() {
      if (!this.current) return [];
      return this.current.configurator || [];
    },
    configTextFields() {
      return this.configurator.filter(addon => addon.type === 'text');
    },
    current() {
      return this.$store.getters['moduleDomains/moduleDnshostOrder/current'] || this.currentItem;
    },
    configLabels() {
      return this.configurator.reduce((acc, item) => {
        acc[item.name] = item.label;
        return acc;
      }, {});
    },
    tools() {
      return this.moduleMainState.tools;
    },
    price() {
      if (!this.pricelist) return 0;
      return parseCost(this.pricelist.price) || 0;
    },
  },
  mounted() {
    if (this.tools && this.tools.new) this.fetch();
    // if (!this.current) {
    //   this.$store.dispatch('moduleDomains/moduleDnshostOrder/setCurrent', +this.id);
    // }
    this.fetchPricelist();
    if (!!this.configurator) {
      setTimeout(() => this.setCurrent(this.current), 1000);
    }
    // if (!this.current) {
    //   this.$store.dispatch('moduleDomains/moduleDnshostOrder/setCurrent', +this.id);
    // }
  },
  beforeRouteLeave() {
    this.reset();
  },
  methods: {
    fetchCalc(data) {
      return this.$store.dispatch('moduleDomains/moduleDnshostOrder/fetchCalc', data);
    },
    setCurrent(item) {
      // console.log(item);
      this.$store.dispatch('moduleDomains/moduleDnshostOrder/setCurrent', this.id);
      if (!!this.configurator)
        this.fetchParams(item).then(() => this.calcSum(this.config._info.model));
    },
    fetchParams(item, period) {
      const params = {};
      if (item) params.id = item.id;
      if (period) params.period = period;
      return this.$store.dispatch('moduleDomains/moduleDnshostOrder/fetchParams', params);
    },
    getFormDataParams(payload = {}) {
      const formData = this.configData ? this.configData : this.config._info.model;
      if (
        this.configData &&
        this.configData.autoprolong &&
        this.configData.autoprolong !== 'null'
      ) {
        formData.autoprolong = this.period;
      }
      const params = {
        pricelist: this.id,
        ...formData,
        ...payload,
        period: this.period,
      };
      return params;
    },
    fetchPricelist() {
      const params = {
        show_metadata: 'on',
        newface: 'on',
      };
      this.$store.dispatch('moduleDomains/moduleDnshostOrder/fetchPricelist', params);
    },
    async fetch() {
      this.isLoading = true;
      const params = {
        itemtype: 'dnshost',
        func: this.tools.new.func,
        period: 12,
      };
      const paramsFirst = await this.stepFirst(params);
      const paramsNext = paramsFirst ? await this.stepNext(paramsFirst) : null;
      if (paramsNext && paramsNext.func) this.func = paramsNext.func;
      this.isLoading = false;
    },
    async stepFirst(params) {
      const { model, list, func } = await this.fetchBillMgrAction(params);
      if (list && list.tariflist && list.tariflist[0]) {
        this.id = list.tariflist[0].pricelist;
        this.pricelist = list.tariflist[0];
      }
      return { ...model, func, pricelist: this.pricelist.pricelist } || null;
    },
    async stepNext(params) {
      const { fields, model, slist, func } = await this.sendBillMgrAction(params);
      if (fields && model) {
        this.config = new BaseConfigurator(
          {
            customfields: fields,
            model,
            slist: slist || [],
          },
          BillMgrAddon
        );
      }
      await this.$store.dispatch('moduleDomains/moduleDnshostOrder/setCurrent', +this.id);
      return { func };
    },
    send(skipBasket) {
      this.skipBasket = skipBasket;
      this.isSending = true;
      const params = {
        ...this.formData,
        func: this.func,
      };
      if (this.skipBasket) params['skipbasket'] = '';
      this.sendBillMgrAction(params)
        .then(data => {
          // console.log(data.itemdesc[0]['item.id']);
          this.order = data.itemdesc[0]['billorder.id'];
          if (data && data.ok) {
            this.showRes(this.skipBasket ? 'successOrdered' : 'successAdded');
            if (this.skipBasket && this.pricelist.price.includes('-100%')) this.freeButton();
          } else this.showRes('fail');
          this.reset();
          this.updatePage();
        })
        .catch(e => this.showError(e))
        .finally(() => (this.isSending = false));
    },
    updateFormData(data) {
      this.formData = { ...data };
    },
    freeButton() {
      const params = {
        id: this.order,
        sok: 'ok',
        func: 'basket',
        clicked_button: 'free',
      };
      this.sendBillMgrAction(params)
        .then(data => {
          // console.log(data);
        })
        .finally(() => {
          this.updatePage();
        });
    },
    calcSum: debounce(function (payload = this.configData) {
      const params = {
        pricelist: this.pricelist.pricelist,
        period: this.period,
      };
      if (payload) Object.assign(params, payload);
      params.period = this.period;
      if (!this.isCalculating) {
        this.isCalculating = true;
        this.fetchCalc(params)
          .then(data => {
            // console.log(data);
            const { cost, cost_details } = data.model;
            this.cost = parseFloat(cost);
            this.costDetails = cost_details;
            this.costDetails.other = this.configTextFields.map(addon => ({
              label: addon.label,
              value: this.configData[addon.name],
            }));
          })
          .catch(e => {
            console.log(e);
            this.showError(e);
          })
          .finally(() => setTimeout(() => (this.isCalculating = false), 100));
      }
    }, 500),
    sendOrder(payload = {}) {
      const params = this.getFormDataParams(payload);
      return this.$store
        .dispatch('moduleDomains/moduleDnshostOrder/sendOrder', params)
        .catch(e => this.showError(e));
    },
    sendToBasket() {
      this.isSendingToBasket = true;
      this.sendOrder()
        .then(data => {
          // this.addToBasketGtm(data, 'dnshost');
          const cost = parseFloat(data.itemdesc[0].total_cost);
          // this.resetCurrent();
          this.reset();
          const text = this.$t('success.basket', { num: this.$n(cost, 'currency') });
          this.$store.dispatch('moduleBasket/fetchBasket');
          this.$store.dispatch('moduleDomains/moduleDomainsDnsHost/fetchList');
          // this.$gtm.trackEvent({
          //   event: '_event_arrange', // Event type [default = 'interaction'] (Optional)
          //   category: 'DnsHost',
          //   action: 'click',
          //   label: 'basket',
          //   value: 5000,
          //   noninteraction: false, // Optional
          // });
          this.showRes('successAdded');
          // this.showSuccessModal(text);
        })
        .finally(() => setTimeout(() => (this.isSendingToBasket = false), 100));
    },
    sendToPay() {
      const payload = {
        not_for_order: 'on',
        skipbasket: '',
        newface: 'on',
        newbasket: 'on',
        clicked_button: 'basket',
        endoflife: 'off',
      };
      const params = this.getFormDataParams(payload);
      this.showResFunc.success = false;
      this.isSendingToPay = true;
      this.sendOrder(params)
        .then(data => {
          this.$store.dispatch('moduleBasket/fetchBasket');
          if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
            const { billorder } = qs.parse(data.ok.v);
            this.startParams = { billorder };
            if (this.cost === 0) {
              const free = {
                clicked_button: 'free',
                func: 'basket',
                ...this.startParams,
              };
              this.sendOrder(free).then(data => {
                if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
                  // this.showSuccessModal(this.$t('success.free'));
                  this.showRes('successOrdered');
                }
              });
              return this.$store.dispatch('moduleDomains/moduleDomainsDnsHost/fetchList');
            } else {
              this.runWizardPay()
                .then(async data => {
                  const res = await this.handleRedirect(data.ok);
                  this.reset();
                  // this.resetCurrent();
                  if (res.ok) {
                    const text =
                      res.func === 'redirect'
                        ? this.$t('success.redirect')
                        : this.$t('success.pay', { num: this.$n(this.cost, 'currency') });
                    this.showRes('successOrdered');
                    // this.showSuccessModal(text);
                  }
                  await this.$store.dispatch('moduleDomains/moduleDomainsDnsHost/fetchList');
                  await this.$store.dispatch('moduleBasket/fetchBasket');
                  this.updateBalance();
                })
                .catch(() => {
                  // const basketItem = this.$store.state.moduleBasket.shadow[billorder][0];
                  // if (basketItem) {
                  //   this.$store
                  //     .dispatch('moduleBasket/removeFromBasket', basketItem)
                  //     .finally(() => {
                  //       this.$store.dispatch('moduleBasket/fetchBasket');
                  //     });
                  // }
                });
              // .finally(() =>
              //   setTimeout(() => {
              //     this.isSendingToBasket = false;
              //     this.$router.push({ name: 'mo365Main' });
              //   }, 100)
              // )
            }
          }
        })
        .finally(() =>
          setTimeout(() => {
            this.isSendingToPay = false;
            this.$store.dispatch('moduleBasket/fetchBasket');
            // this.$router.push({ name: 'Domains' });
          }, 100)
        );
    },
    onConfigInit(data) {
      this.updateFormData(data);
      this.configIsInit = true;
      this.calcSum();
    },
    onConfigReady(data) {
      this.updateFormData(data);
      this.configIsValid = true;
    },
    onConfigNotReady(data) {
      this.updateFormData(data);
      this.configIsValid = false;
    },
    getModuleState(str) {
      const splitted = str.split('.');
      const path = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      return path;
    },
    showRes(res) {
      this.$modals.open({
        name: 'OrderRes',
        title: this.$t(`results.${res}.title`),
        text: this.$t(`results.${res}.text`),
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('ok') },
            on: {
              click: () => this.$modals.close(),
            },
          },
        },
      });
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
    reset() {
      this.configIsInit = false;
      this.configIsValid = false;
      this.formData = null;
      this.config = null;
      this.pricelist = null;
      this.isLoading = false;
      this.isSending = false;
      this.skipBasket = false;
      this.func = '';
    },
    updatePage() {
      this.$store.dispatch(`${this.moduleMainPath}/fetchList`);
      this.$store.dispatch('moduleBasket/fetchBasket');
      this.$store.dispatch('moduleProfile/setProfileInfo');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "summary": "Итого",
    "order": "Оплатить",
    "activate": "Активировать",
    "basket": "В корзину",
    "results": {
      "successOrdered": {
        "title": "Успешно",
        "text": "Услуга успешно подключена"
      },
      "successAdded": {
        "title": "Успешно",
        "text": "Услуга успешно добавлена в корзину"
      },
      "success": {
        "basket": "Заказ на {num} успешно добавлен в корзину",
        "pay": "Хостинг успешно создан, {num} списано с лицевого счета",
        "free": "Хостинг успешно создан, дождитесь обновления данных",
        "redirect": "Хостинг успешно создан, продолжите оплату и дождитесь обновления данных"
      },
      "fail": {
        "title": "Ошибка",
        "text": "Сожалеем, что-то пошло не так. Попробуйте позднее"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.dns-host-add {
  &__summary {
    flexy(flex-start, baseline);

    &-price {
      margin-left: 0.5rem;
    }
  }

  &__btns {
    margin: -0.5rem;
  }

  &__btn {
    margin: 0.5rem;
  }
}
</style>
