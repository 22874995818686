<template>
  <div class="domains-context-menu">
    <div v-for="(group, i) in menu" :key="i" class="domains-context-menu__group">
      <component
        :is="item.attrs ? (item.attrs.to ? 'router-link' : 'a') : 'div'"
        v-for="item in group"
        :key="item.key"
        v-bind="item.attrs"
        class="domains-context-menu__item standart-title"
        :class="{ [`domains-context-menu__item--${item.color}`]: !!item.color }"
        :disabled="item.disabled"
        @click="onClick(item)"
      >
        {{ $t(`actions.${item.key}`) }}
      </component>
    </div>
  </div>
</template>

<script>
import { BillMgrTool } from '@/models/BillMgr/Tools';
import serialize from '@/utils/serialize';
import { isEqual } from 'lodash';
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import OrderConfig from '@/components/Configurator/OrderConfig.vue';
import Vue from 'vue';
import { IspTool, IspTools } from '@/models/base/IspTools';
import { DnsMgrTool } from '@/models/DnsMgr/DnsMgrTools';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import qs from 'qs';
// import Link from '@/components/Configurator/components/Link';
import redirectLink from '@/mixins/redirectLink';
export default {
  name: 'DomainsContextMenu',
  mixins: [showErrorModal, redirectLink],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    menu: {
      type: Array,
      default: () => [],
    },
    tools: {
      type: IspTools,
      required: true,
    },
    moduleMain: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modal: null,
    };
  },
  computed: {
    moduleMainPath() {
      return this.moduleMain.replaceAll('.', '/');
    },
  },
  methods: {
    async onClick(item) {
      if (item.disabled) return;
      const sure = item.askSure ? await this.askSure(item) : true;
      if (sure) {
        if (item.handler && typeof item.handler === 'function') {
          item.handler(item);
          // console.log(1);
        } else if (item.tool && item.tool instanceof IspTool) {
          // console.log(2);
          const handler =
            item.tool instanceof BillMgrTool
              ? this.handleBillMgrTool
              : item.tool instanceof DnsMgrTool
              ? this.handleDnsMgrTool
              : null;
          if (typeof handler === 'function') {
            this.showLoadingModal();
            const res = await handler(item);
            if (res === 'cancel' && this.modal) this.$modals.close();
            else this.showResModal(res);
          } else {
            console.error(`Handler for tool "${item.tool.name}" is not a function`);
          }
        } else if (!item.attrs) {
          alert(`id: ${this.tariff.id}, \r\naction: ${item.title}`);
        }
      }
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') {
        // let redirectLink = window.open();
        this.goToWindow(item);
        return Promise.resolve('cancel');
      } else {
        const fetchAction = 'fetchBillMgrToolAction';
        const sendAction = 'sendBillMgrToolAction';
        return this.handleIspTool(item, fetchAction, sendAction);
      }
    },
    handleDnsMgrTool(item) {
      // console.log('handleDnsMgrTool');
      const fetchAction = 'fetchDnsMgrToolAction';
      const sendAction = 'sendDnsMgrToolAction';
      return this.handleIspTool(item, fetchAction, sendAction);
    },
    handleIspTool(item, fetchAction, sendAction) {
      let params = {
        ...this.tariff.toolParams,
        func: item.tool.func,
      };
      return new Promise(async resolve => {
        let needSending = item.tool.name !== 'edit';
        if (item.tool.name === 'edit') {
          await this.$store
            .dispatch(`${this.moduleMainPath}/${fetchAction}`, params)
            .then(async data => {
              const { fields, hidefields, model, slist } = data;
              const config = new BaseConfigurator({
                customfields: fields,
                hidefields,
                model,
                slist,
              });
              const res = await this.showEditModal(config);
              if (res === 'cancel') resolve('cancel');
              else if (res === 'fail') resolve('fail');
              else {
                params = { ...params, ...res };
                needSending = true;
              }
            })
            .catch(() => resolve('fail'));
        }
        if (needSending) {
          // console.log(`${this.moduleMainPath}/updateDnsList`);
          // await this.$store.dispatch(`${this.moduleMainPath}/updateDnsList`);
          this.$store
            .dispatch(`${this.moduleMainPath}/${sendAction}`, params)
            .then(data => {
              if (data.ok) {
                this.$store.dispatch(`${this.moduleMainPath}/updateList`);
                resolve('success');
              } else resolve('fail');
            })
            .catch(() => {
              resolve('fail');
            });
        }
      });
    },
    askSure(item) {
      const title = this.tariff.itemType ? this.tariff.itemType : this.tariff.title;
      return new Promise(resolve => {
        const props = {
          text: this.$t('modal.sure.text', {
            msg: this.$t(`actions.${item.key}`) + ' ' + title,
          }),
          footer: {
            confirm: {
              props: {
                title: this.$t('modal.sure.confirm'),
              },
              on: {
                click: () => {
                  resolve(true);
                  // this.$store.dispatch('moduleDomains/moduleDomainsDnsHost/fetchList');
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  this.$modals.close();
                  resolve(false);
                },
              },
            },
          },
        };
        this.makeModal(props);
      });
    },
    makeModal(props) {
      this.$modals.open({
        name: 'ContextMenuModal',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showLoadingModal() {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'text', null);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'footer', false);
    },
    showEditModal(config) {
      return new Promise(resolve => {
        if (!config || !(config instanceof BaseConfigurator)) resolve('fail');
        if (!this.modal) this.makeModal();
        let formData = {};
        let formDataBackup = null;
        Vue.set(this.modal, 'props', { period: 12, configurator: config });
        Vue.set(this.modal, 'text', null);

        Vue.set(this.modal, 'on', {
          init: data => {
            Object.assign(formData, data);
            formDataBackup = { ...formData };
          },
          ready: data => {
            Object.assign(formData, data);
            if (!isEqual(formData, formDataBackup)) {
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            }
          },
          notready: data => {
            Object.assign(formData, data);
            Vue.set(this.modal.footer.confirm.props, 'disabled', true);
          },
          change: data => {
            Object.assign(formData, data);
            if (
              formData &&
              formData.rtype === 'txt' &&
              formData.value &&
              formData.value.includes('\n')
            )
              formData.value = formData.value.replaceAll('\n', '');
            let hasChanges = !isEqual(formData, formDataBackup);
            Vue.set(this.modal.footer.confirm.props, 'disabled', !hasChanges);
          },
        });
        Vue.set(this.modal, 'component', OrderConfig);
        Vue.set(this.modal, 'closeOnBackdrop', false);
        Vue.set(this.modal, 'footer', {
          confirm: {
            props: { title: this.$t('modal.submit'), disabled: true },
            on: { click: () => resolve(formData) },
          },
          cancel: {
            props: { title: this.$t('modal.cancel') },
            on: { click: () => resolve('cancel') },
          },
        });
      });
    },
    showResModal(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', this.$t(`modal.res.${res}`));
      Vue.set(this.modal, 'footer', {
        centered: true,
        cancel: {
          props: { title: this.$t('close'), theme: 'filled' },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
      }
    },
    goToWindow(item) {
      this.$store
        .dispatch('fetchBillMgrToolAction', {
          func: item.tool.func,
          id: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let redirectLink = window.open();
            let payload = item.payload ? serialize(item.payload) : '';
            this.$modals.close();
            const stringUrl = data.ok.v.split('?');
            const url = qs.parse(stringUrl[1]);
            url.backurl = window.location.href;
            url.backname = 'Личный кабинет';
            const newUrl = stringUrl[0] + '?' + qs.stringify(url);
            if (redirectLink) redirectLink.location.href = newUrl + payload;
            else {
              this.$modals.close();
              this.showModal(newUrl + payload);
            }
            // window.open(newUrl + payload);
          }
        })
        .catch(e => {
          this.$modals.close();
          this.showError(e);
        });
    },
    deleteFromBasket() {
      let modal = null;
      this.$modals.open({
        name: 'RemoveBasketResult',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.$store
        .dispatch('moduleBasket/removeFromBasket', this.tariff.id)
        .then(() => {
          Vue.set(modal, 'component', null);
          Vue.set(modal, 'text', this.$t('modal.removeFromBasket.success'));
          Vue.set(modal, 'closable', true);
          this.$store.dispatch(`${this.moduleMainPath}/updateList`);
          if (this.$route.params.id && this.$route.params.id == this.tariff.id) {
            this.$router.push({ name: 'domainsMain' });
          }
        })
        .catch(e => this.showError(e));
    },
  },
};
// "ns": "Серверы имён (NS)",
</script>

<i18n>
{
  "ru": {
    "actions": {
      "ns": "Изменить NS-сервера",
      "whois": "Whois",
      "dns": "DNS-записи",
      "gotoserver": "перейти в панель",
      "history": "история",
      "remove": "удалить",
      "delete": "удалить",
      "removeOrder": "удалить заказ",
      "edit": "изменить"
    },
    "modal": {
      "submit": "Подтвердить",
      "cancel": "Отменить",
      "sure": {
        "text": "Вы собираетесь %{msg}. Вы уверены?",
        "confirm": "Да, уверен"
      },
      "res": {
        "success": "Запрос выполняется. Пожалуйста, дождитесь обновления данных.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      },
      "removeFromBasket": {
        "success": "Заказ успешно удалён"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.domains-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.domains-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
